import { LocationSnapshot } from 'packages/history';
import { Gateway } from './gateway-types';
import {
  GATEWAY_EVENT_URL_CHANGE_COMPLETED,
  GATEWAY_EVENT_URL_CHANGE_ERROR,
  GATEWAY_EVENT_INJECTABLE_LOADED,
  GATEWAY_EVENT_INJECTABLE_LOAD_ERROR,
  GATEWAY_EVENT_MFE_MOUNTED,
  GATEWAY_EVENT_URL_CHANGE_STARTED,
  GATEWAY_EVENT_STOPPED,
  GATEWAY_EVENT_LINK_INTERCEPT,
  GATEWAY_EVENT_LINK_INTERCEPT_ERROR,
  LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION,
  LINK_INTERCEPT_ERROR_HANDLER_MISSING,
  GATEWAY_EVENT_STARTED,
  GATEWAY_EVENT_MFE_UNMOUNTED,
  GATEWAY_EVENT_MFE_MOUNT_ERROR,
  GATEWAY_EVENT_MFE_UNMOUNT_ERROR
} from './gateway-event';
/* eslint-disable no-console */

export const registerGatewayDebugger = (
  addGatewayListener: Gateway['addGatewayListener']
) => {
  const PREFIX = '[GATEWAY]';

  const getFullPath = (loc: LocationSnapshot) =>
    loc.href.substring(loc.origin.length);

  addGatewayListener(GATEWAY_EVENT_STARTED, () => {
    console.info(`${PREFIX} Startup`);
  });

  addGatewayListener(GATEWAY_EVENT_STOPPED, () => {
    console.info(`${PREFIX} Stopping`);
  });

  addGatewayListener(GATEWAY_EVENT_URL_CHANGE_STARTED, ({ from, to }) => {
    console.log(`${PREFIX} URL Changed to ${getFullPath(to)}`);
    console.info(`From: ${getFullPath(from)}`);

    console.group('Injectable Logs');
  });

  addGatewayListener(GATEWAY_EVENT_URL_CHANGE_ERROR, ({ error, location }) => {
    console.groupEnd();
    console.log(
      `${PREFIX} Error Prevented URL Change to ${getFullPath(location)} `
    );
    console.error(error);
  });

  addGatewayListener(GATEWAY_EVENT_URL_CHANGE_COMPLETED, ({ activeMfes }) => {
    console.groupEnd();
    console.info(
      `${PREFIX} Active Micro FrontEnds: [ ${activeMfes.join(', ')} ]`
    );
  });

  addGatewayListener(
    GATEWAY_EVENT_LINK_INTERCEPT,
    ({ params: { args, handler, namespace }, location }) => {
      console.log(`${PREFIX} Intercepted Link ${getFullPath(location)}`);
      console.log({
        linkInterceptParams: {
          microFrontEnd: namespace,
          linkName: handler,
          linkArgs: args
        }
      });
    }
  );

  addGatewayListener(
    GATEWAY_EVENT_LINK_INTERCEPT_ERROR,
    ({ params: { args, handler, namespace }, error, reason }) => {
      if (reason === LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION) {
        console.error(
          `Error Occurred in link handler.\n\t ${namespace}.links.${handler}.url(${JSON.stringify(
            args,
            null,
            2
          )})`,
          error
        );
      } else if (reason === LINK_INTERCEPT_ERROR_HANDLER_MISSING) {
        console.error(
          `MFE Links Handler Does Not Exist!! \n${namespace}.links.${handler}`
        );
      }
    }
  );

  addGatewayListener(
    GATEWAY_EVENT_INJECTABLE_LOADED,
    ({ instance, dependencies, stackTrace }) => {
      console.group(`${PREFIX} Injectable Ready`);
      console.info({ injectableInstance: instance });

      console.groupCollapsed('Call Stack');
      console.log(stackTrace);
      console.groupEnd();

      const dependencyCount = Object.keys(dependencies).length;
      console.log(`Dependencies [${dependencyCount}]:`);
      console.dir(dependencies);
      console.groupEnd();
    }
  );

  addGatewayListener(
    GATEWAY_EVENT_INJECTABLE_LOAD_ERROR,
    ({ error, stackTrace }) => {
      console.error(`${error}\n\n${stackTrace}`);
    }
  );

  addGatewayListener(GATEWAY_EVENT_MFE_MOUNTED, ({ impl, mfe, name }) => {
    console.log(`${PREFIX} MicroFrontend Mounted: ${name}`);
    console.info({
      microFrontendDeclaration: mfe,
      microFrontendImplementation: impl
    });
  });

  addGatewayListener(
    GATEWAY_EVENT_MFE_MOUNT_ERROR,
    ({ impl, mfe, name, error }) => {
      console.log(`Error during MicroFrontend Mount: ${name}`);
      console.error(error);
      console.info({
        microFrontendDeclaration: mfe,
        microFrontendImplementation: impl
      });
    }
  );

  addGatewayListener(GATEWAY_EVENT_MFE_UNMOUNTED, ({ impl, mfe, name }) => {
    console.log(`${PREFIX} MicroFrontend Unmounted: ${name}`);
    console.info({
      microFrontendDeclaration: mfe,
      microFrontendImplementation: impl
    });
  });

  addGatewayListener(
    GATEWAY_EVENT_MFE_UNMOUNT_ERROR,
    ({ impl, mfe, name, error }) => {
      console.log(`Error during MicroFrontend Unmount: ${name}`);
      console.error(error);
      console.info({
        microFrontendDeclaration: mfe,
        microFrontendImplementation: impl
      });
    }
  );
};
