export const ERROR_INSTANTIATE = 'instantiate';
export const ERROR_LOAD = 'load';
export const ERROR_DEPENDENCY = 'dependency';
export type InjectableLoadErrorType =
  | typeof ERROR_INSTANTIATE
  | typeof ERROR_LOAD
  | typeof ERROR_DEPENDENCY;

export class InjectableLoadError extends Error {
  constructor(
    public readonly type: InjectableLoadErrorType,
    public readonly innerError: Error
  ) {
    super();
  }
}
