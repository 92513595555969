export const once = <T>(func: () => T): (() => T) => {
  let result: any;
  return () => {
    if (func) {
      result = func();
      (func as any) = null;
    }
    return result;
  };
};
