import { LocationSnapshot } from 'packages/history';
import { RpcMatcher } from './rpc-linker';
import { GatewayEventEmitter } from './util/event-emitter';
import { createLock } from './util/lock';
import {
  InternalMfeDeclaration,
  MfeMountParameters,
  MfeImplementation
} from './gateway-types';
import { once } from './util/once';
import {
  createLinkInterceptGatewayEvent,
  createLinkInterceptErrorGatewayEvent,
  LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION,
  LINK_INTERCEPT_ERROR_HANDLER_MISSING,
  createUrlChangeCompletedGatewayEvent,
  createUrlChangeInterruptedGatewayEvent,
  createUrlChangeErrorGatewayEvent,
  createUrlChangeRedirectedGatewayEvent,
  createMfeMountErrorGatewayEvent,
  createMfeMountedGatewayEvent,
  createMfeNoImplGatewayEvent,
  createMfeUnmountGatewayEvent,
  createMfeUnmountErrorGatewayEvent,
  resolveChosenMfeErrorGatewayEvent
} from './gateway-event';

export interface GatewayUpdater {
  (locationSnapshot: LocationSnapshot): /* cancel */ () => void;
}

const resolveChosenMfeFactory = (emit: GatewayEventEmitter[1]) => ({
  impls
}: InternalMfeDeclaration<any>) =>
  Promise.all(
    impls.map((impl) =>
      impl.when
        .load()
        .then((ask) => ask())
        .catch((error) => {
          emit(resolveChosenMfeErrorGatewayEvent(impl, error));
          return false;
        })
    )
  ).then((conditions) => impls[conditions.indexOf(true)]);

export const createGatewayUpdater = (
  emit: GatewayEventEmitter[1],
  rpcMatcher: RpcMatcher,
  mountParameters: MfeMountParameters,
  declarations: Array<InternalMfeDeclaration<any>>
): GatewayUpdater => {
  const acquireNavigationCancelLock = createLock();
  const activeMfes = new Map<
    InternalMfeDeclaration<any>,
    MfeImplementation<any>
  >();
  const { history } = mountParameters;

  return (locationSnapshot) => {
    let cancelled = false;

    // This is the big process that:
    // Kick off a process that runs async without. After some async points,
    // it will check if it should be exit early
    // - Checks if the incoming update is for an RPC link
    //   - If so, find the correct MFE impl
    //   - Load the MFE impl and ask for its link instance
    //   - Replace the current history state with the proper link url
    //   - Follow standard url update process which loads and mounts the MFE
    // - Checks the incoming url update for all MFEs that should be active
    // - Check the url for any active MFEs that should be deactivated
    // - Deactivate and activate the proper MFEs
    (async () => {
      if (cancelled) return;
      // First we will check if this is a redirect using the RPC links
      const rpcParams = rpcMatcher.match(locationSnapshot.pathname);
      const rpcMfe =
        (rpcParams &&
          declarations.find(({ name }) => name === rpcParams.namespace)) ||
        null;
      // If it is an RPC link, handle it and do not (de)activate any MFEs
      if (rpcParams && rpcMfe) {
        emit(createLinkInterceptGatewayEvent(locationSnapshot, rpcParams));
        const { handler, args } = rpcParams;
        let links: any;
        try {
          // Find the chosen MFE
          const resolveChosenMfe = resolveChosenMfeFactory(emit);
          const chosenApp = await resolveChosenMfe(rpcMfe);
          if (cancelled) return;
          // Load the chosen mfe impl, we only need the links for it, we wont mount it
          links = (await chosenApp.mfe.load()).links;
          if (cancelled) return;

          if (handler in links) {
            // Navigate!
            history.replaceState(
              null,
              '',
              links[handler].url(...(args as any[]))
            );
          } else {
            emit(
              createLinkInterceptErrorGatewayEvent(
                locationSnapshot,
                rpcParams,
                new Error(),
                LINK_INTERCEPT_ERROR_HANDLER_MISSING
              )
            );
          }
        } catch (error) {
          emit(
            createLinkInterceptErrorGatewayEvent(
              locationSnapshot,
              rpcParams,
              error,
              LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION
            )
          );
          throw error;
        }
        return true;
      }
      // If not a RPC link, lets find which MFE wants it!
      else {
        // Ask which impl of the MFE to use and activate
        const resolveChosenMfe = resolveChosenMfeFactory(emit);
        const toActivate = await Promise.all(
          declarations
            .filter((mfe) => !activeMfes.has(mfe))
            .filter((mfe) => mfe.root.match(locationSnapshot.pathname, false))
            .map((mfe) =>
              resolveChosenMfe(mfe).then((impl) => [mfe, impl] as const)
            )
        );
        if (cancelled) return;
        // Load the chosen MFE impl
        const toActivateImpls = await Promise.all(
          toActivate.map(([mfe, impl]) =>
            Promise.resolve(impl && impl.mfe.load()).then(
              (instance?: MfeImplementation<any>) => [mfe, instance] as const
            )
          )
        );
        if (cancelled) return;

        //
        // Acquire navigation lock!
        // Up until this point, we have only done loading of MFE stuff,
        // we have not mounted or updated the global map. During this
        // stage, we will acquire the lock. At certain points after async
        // work, we will still check if we have been cancelled and will
        // still release the lock and stop working if possible
        //

        const release = await acquireNavigationCancelLock();
        if (cancelled) return release();

        await Promise.all(
          Array.from(activeMfes.keys())
            .filter((mfe) => !mfe.root.match(locationSnapshot.pathname, false))
            .map(async (mfe) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const instance = activeMfes.get(mfe)!;
              try {
                await instance.unmount();
                activeMfes.delete(mfe);
                emit(
                  createMfeUnmountGatewayEvent(mfe.name, mfe.decl, instance)
                );
              } catch (error) {
                // swallow error here, an error un-mounting should not result in a halted gateway
                // TODO: confirm behavior
                emit(
                  createMfeUnmountErrorGatewayEvent(
                    mfe.name,
                    mfe.decl,
                    instance,
                    error
                  )
                );
              }
            })
        );
        if (cancelled) return release();

        await Promise.all(
          toActivateImpls.map(async ([mfe, instance]) => {
            if (instance) {
              try {
                await instance.mount(mountParameters);
                activeMfes.set(mfe, instance);
                emit(
                  createMfeMountedGatewayEvent(mfe.name, mfe.decl, instance)
                );
              } catch (error) {
                // swallow error here, an error mounting should not result in a halted gateway
                // TODO: confirm behavior
                emit(
                  createMfeMountErrorGatewayEvent(
                    mfe.name,
                    mfe.decl,
                    instance,
                    error
                  )
                );
              }
            } else {
              emit(createMfeNoImplGatewayEvent(mfe.name, mfe.decl));
            }
          })
        );

        const activeMfeDeclarations = Array.from(activeMfes.keys()).map(
          ({ name }) => name
        );
        release();
        return activeMfeDeclarations;
      }
    })().then(
      (result) =>
        emit(
          result === true
            ? createUrlChangeRedirectedGatewayEvent(locationSnapshot)
            : Array.isArray(result)
            ? createUrlChangeCompletedGatewayEvent(locationSnapshot, result)
            : createUrlChangeInterruptedGatewayEvent(locationSnapshot)
        ),
      (error) => emit(createUrlChangeErrorGatewayEvent(locationSnapshot, error))
    );

    return once(() => {
      cancelled = true;
    });
  };
};
