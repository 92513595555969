export const createLock = () => {
  let lock = Promise.resolve();
  return /* acquire */ () =>
    new Promise<() => void>((resolve) => {
      lock = lock.then(
        () =>
          new Promise<void>((release) => {
            resolve(release);
          })
      );
    });
};
