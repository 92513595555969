import { GATEWAY_EVENT, GatewayEventMap } from '../gateway-event';

export type GatewayEventEmitter = readonly [
  /* on */ <T extends GATEWAY_EVENT>(
    event: T,
    handler: (event: GatewayEventMap[T]) => void
  ) => /* off */ () => void,
  /* emit */ (event: GatewayEventMap[GATEWAY_EVENT]) => void
];

export const createEventEmitter = (): GatewayEventEmitter => {
  const emitter = document.createElement('div');

  return [
    (event, handler) => {
      const wrapper = (event: any) => handler(event.detail);
      emitter.addEventListener(event, wrapper);
      return () => emitter.removeEventListener(event, wrapper);
    },
    (event) => {
      emitter.dispatchEvent(
        new CustomEvent(event.type, {
          detail: event
        })
      );
    }
  ];
};
