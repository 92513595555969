import { jsonBase64Matcher, LinksOf, ParamsOfLink, Link } from 'packages/links';

/**
 * This file helps with the magic of type-safe link resolution. Each MFE
 * provides a object hash of links that then can be injected into a peer
 * MFE. The issue is that we do not want to load and create a MFE, just to
 * have links that we then inject into the MFE that *should* be active,
 * that would be harmful to performance. Instead, when a MFE asks for the
 * links of another MFE, the gateway creates an object proxy that will
 * generate a url for any link ever requested (regardless of whether it
 * actually exists). Then, when that link is clicked on or navigated to,
 * the Gateway will detect the link and at that time it will load the target
 * MFE that is supposed to handle the link. It will find the real links
 * object and then it will redirect to that link. Then the Gateway will
 * activate the target MFE as normal.
 *
 * Example:
 *
 * Given the setup;
 *
 * ```ts
 * const contacts = declareMicroFrontend('contacts', createLink`/contacts`());
 * const dashboard = declareMicroFrontend('dashboard', createLink`/dashboard`());
 *
 * contacts.implement(() => (dependencies): MfeFactory => ({ root }) => ({
 *  links: {
 *    createNewContact: root.extend`/create/${'contactName'}`
 *  },
 *  // mount, unmount, etc...
 * }))
 *
 * dashboard.implement(() => (dependencies): MfeFactory => ({ root }) => ({
 *  mount({ history }) {
 *    function onCreateNewContact() {
 *      const createContactUrl = dependencies.contacts.createNewContact.url({ contactName: 'Joel' });
 *      history.pushState(null, '', createContactUrl);
 *    }
 *
 *    document.getElementById('createContact').onclick = onCreateNewContact;
 *  }
 * }), { contacts })
 * ```
 *
 * Then when the dashboard is mounted, and the button with id `createContact` is clicked,
 * then the URL changes to an intermediate url like
 *
 * `/<base-url>/gateway/r/contacts/createNewContact/WyJKb2VsIl0=`
 *
 * Which translates to
 *
 * `/<base-url>/<gatewayId>/r/<nameOfDeclaredMfe>/<nameOfLinkHandler>/<btoa(JSON.stringify(argumentsArray))>`
 *
 * The Gateway then detects the link change that matches its RPC handler
 * The Gateway then loads the MFE matching the namespace name
 * The Gateway looks for links of the loaded MFE and a link name matching the handler name
 * The Gateway then calls `history.replaceState()` with the new URL
 * The Gateway then detects a URL change like normal and loads the instance of the MFE and activates like normal
 *
 * The final URL visible looks like:
 *
 * `/<base-url>/contacts/create/Joel`
 *
 */

const createRpcMatcher = (baseUrl: Link, gatewayId: string) => {
  const prefix = `/${gatewayId}/r/`;
  // Using fake template string to support a dynamic string that is not a variable
  return baseUrl.extend(
    ([prefix, '/', '/', ''] as unknown) as TemplateStringsArray,
    // Namespace is the name of the MFE as provided to "declareMicroFrontend"
    'namespace',
    // Handler is the name of the link that should be found on the "links" object
    'handler',
    // Args is a JSON serialized array of args that were used
    'args'
  )({
    args: jsonBase64Matcher
  });
};

export type RpcMatcher = ReturnType<typeof createRpcMatcher>;
export type RpcParams = ParamsOfLink<RpcMatcher>;

// Create a new Link for matching and also create a function
// that will create a proxy object for generating links for a given
// MFE.
export const createWeakRpcLinker = (baseUrl: Link, gatewayId: string) => {
  const rpcMatcher = createRpcMatcher(baseUrl, gatewayId);
  const createRpcLinkProxy = <Links extends object>(namespace: string) =>
    new Proxy<LinksOf<Links>>({} as any, {
      get(obj: any, handler: string) {
        if (!obj[handler]) {
          obj[handler] = {
            url: (...args: any[]) =>
              rpcMatcher.url({ namespace, handler, args })
          };
        }
        return obj[handler];
      }
    });

  return [rpcMatcher, createRpcLinkProxy] as const;
};
