import { createGateway } from './gateway';
import { getBaseUrl } from './get-base-url';
export { Injectable, InjectablesOf } from './types';
export {
  GatewayEvent,
  GatewayEventMap,
  InjectableLoadErrorGatewayEvent,
  InjectableLoadedGatewayEvent,
  StartedGatewayEvent,
  StoppedGatewayEvent,
  LinkInterceptGatewayEvent,
  LinkInterceptErrorGatewayEvent,
  UrlChangeCompletedGatewayEvent,
  UrlChangeErrorGatewayEvent,
  UrlChangeInterruptedGatewayEvent,
  UrlChangeStartedGatewayEvent
} from './gateway-event';
import { registerGatewayDebugger as debug } from './debugger';
export {
  Gateway,
  MfeDeclaration,
  MfeFactory,
  MfeImplementation,
  MfeMountParameters
} from './gateway-types';
export { createGateway };
export const baseUrl = getBaseUrl(document);

// Create and export default gateway.
// `createGateway` can be called to created additional ones
// but this is easier for prod impl
export const {
  addGatewayListener,
  createInjectable,
  declareMicroFrontend,
  start
} = createGateway();

// Turn on the console debugger. Defaults to the shared instance
export const registerGatewayDebugger = (addListener = addGatewayListener) =>
  debug(addListener);
