import { addHistoryListener, getLocationSnapshot } from 'packages/history';
import { createWeakRpcLinker } from './rpc-linker';
import { injectableCreatorFactory } from './injectable';
import { createEventEmitter } from './util/event-emitter';
import {
  createStartedGatewayEvent,
  createStoppedGatewayEvent,
  createUrlChangeStartedGatewayEvent
} from './gateway-event';
import {
  Gateway,
  InternalMfeDeclaration,
  InternalMfeImplementation,
  MfeDeclaration,
  MfeMountParameters
} from './gateway-types';
import { createGatewayUpdater } from './gateway-updater';
import { once } from './util/once';
import { getBaseUrl } from './get-base-url';

export const createGateway = (
  baseUrl = getBaseUrl(document),
  gatewayId = 'gateway'
): Gateway => {
  const [addGatewayListener, emit] = createEventEmitter();
  const createInjectable = injectableCreatorFactory(emit);
  const declarations: Array<InternalMfeDeclaration<any>> = [];
  const [rpcMatcher, createLinkProxy] = createWeakRpcLinker(baseUrl, gatewayId);

  return {
    addGatewayListener,
    createInjectable,
    declareMicroFrontend: (name, root) => {
      if (declarations.some(({ name: otherName }) => name === otherName)) {
        throw new Error(`MFE with name "${name}" has already been declared.`);
      }
      const impls: Array<InternalMfeImplementation<any>> = [];
      const decl: MfeDeclaration<any> = createInjectable(() => () =>
        createLinkProxy(name)
      ) as any;
      decl.implement = function (
        factory,
        // Default to true, override to 'gate' the implementation
        when = createInjectable(() => () => () => true)
      ) {
        const mfe = createInjectable(() => (deps) => deps.factory({ root }), {
          factory
        });
        // Add our implementation to the list of implementations to chose from
        impls.push({ when, mfe });
        return decl;
      };
      // Define readonly properties
      Object.defineProperties(decl, {
        name: { value: name },
        root: { value: root }
      });
      declarations.push({ name, decl, root, impls });
      return decl;
    },
    start: (history = window.history, location = window.location) => {
      let cancelPrevious = () => {
        // noop
      };
      let lastLocationSnapshot = getLocationSnapshot(location);
      const mountParameters: MfeMountParameters = { history, location };
      const doUpdate = createGatewayUpdater(
        emit,
        rpcMatcher,
        mountParameters,
        declarations
      );
      const update = () => {
        const locationSnapshot = getLocationSnapshot(location);
        emit(
          createUrlChangeStartedGatewayEvent(
            lastLocationSnapshot,
            locationSnapshot
          )
        );
        lastLocationSnapshot = locationSnapshot;
        cancelPrevious();
        cancelPrevious = doUpdate(locationSnapshot);
      };
      const stop = addHistoryListener(history, update);
      emit(createStartedGatewayEvent());
      update();

      return /* stop */ once(() => {
        stop();
        cancelPrevious();
        emit(createStoppedGatewayEvent());
      });
    }
  };
};
