import { createLink } from 'packages/links';

/**
 * A `baseUrl: Link` based on a document.baseURI
 * The trailing '/' (if any) is removed for convenience of "baseUrl.extend`/dashboard`()"
 */
export const getBaseUrl = (doc: Document) => {
  const {
    baseURI,
    location: { origin }
  } = doc;

  // Strip off a trailing slash if present, and remove the document origin
  const cleanedBaseURI = baseURI
    .slice(0, baseURI[baseURI.length - 1] === '/' ? -1 : 0)
    .replace(origin, '');

  const templateLiteral: TemplateStringsArray = [cleanedBaseURI] as any;
  (templateLiteral as any).raw = cleanedBaseURI;

  return createLink(templateLiteral)();
};
