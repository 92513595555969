import {
  Injectable,
  InjectablesOf,
  PromiseOrType,
  ModuleResult
} from './types';
import { once } from './util/once';
import {
  GatewayEvent,
  createInjectableLoadErrorGatewayEvent,
  createInjectableLoadedGatewayEvent
} from './gateway-event';
import {
  InjectableLoadError,
  ERROR_LOAD,
  ERROR_DEPENDENCY,
  ERROR_INSTANTIATE
} from './injectable-load-error';

export interface InjectableCreator {
  <Type>(
    loader: () => PromiseOrType<ModuleResult<() => PromiseOrType<Type>>>
  ): Injectable<Type>;
}
export interface InjectableCreator {
  <Type, Dependencies extends object>(
    loader: () => PromiseOrType<
      ModuleResult<(dependencies: Dependencies) => PromiseOrType<Type>>
    >,
    dependencies: InjectablesOf<Dependencies>
  ): Injectable<Type>;
}

/**
 * Throws an immediately catches a fake error to capture a stack trace
 */
const getStackTraceOfCaller = (): string => {
  try {
    throw new Error('Fake Error');
  } catch (e) {
    return e.stack.split('\n').slice(3).join('\n');
  }
};

export const injectableCreatorFactory = (
  emit: (event: GatewayEvent<any>) => void
): InjectableCreator => <Type>(
  loader: () => PromiseOrType<
    ModuleResult<(deps?: any) => PromiseOrType<Type>>
  >,
  dependencies: any = {}
): Injectable<Type> => {
  const stackTrace = getStackTraceOfCaller();
  const injectable: Injectable<Type> = {} as any;
  const handleError = (error: InjectableLoadError): never => {
    emit(createInjectableLoadErrorGatewayEvent(injectable, error, stackTrace));
    throw error;
  };
  injectable.load = once(async () => {
    const dependencyKeys = Object.keys(dependencies);
    // Start loading the dependencies now so they can load in parallel. We don't wait for them here
    const loadedDependenciesPromise = Promise.all(
      dependencyKeys.map((dep) => dependencies[dep].load())
    );
    let loadedServiceFactory: (deps?: any) => PromiseOrType<Type>;
    let loadedDependencies: any[];

    try {
      const loadedServiceFactoryModule = await loader();
      loadedServiceFactory =
        loadedServiceFactoryModule && 'default' in loadedServiceFactoryModule
          ? loadedServiceFactoryModule.default
          : loadedServiceFactoryModule;
    } catch (error) {
      return handleError(new InjectableLoadError(ERROR_LOAD, error));
    }

    try {
      loadedDependencies = await loadedDependenciesPromise;
    } catch (error) {
      return handleError(new InjectableLoadError(ERROR_DEPENDENCY, error));
    }

    try {
      const mappedDependencies = loadedDependencies.reduce((acc, dep, i) => {
        (acc as any)[dependencyKeys[i]] = dep;
        return acc;
      }, {} as any);

      const dependencyInstance = await (dependencyKeys.length
        ? loadedServiceFactory(mappedDependencies as any)
        : loadedServiceFactory());

      emit(
        createInjectableLoadedGatewayEvent(
          injectable,
          dependencyInstance,
          mappedDependencies,
          stackTrace
        )
      );
      return dependencyInstance;
    } catch (error) {
      return handleError(new InjectableLoadError(ERROR_INSTANTIATE, error));
    }
  });

  return injectable;
};
