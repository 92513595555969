import { Injectable } from './types';
import { InjectableLoadError } from './injectable-load-error';
import { LocationSnapshot } from 'packages/history';
import { RpcParams } from './rpc-linker';
import {
  MfeDeclaration,
  MfeImplementation,
  InternalMfeImplementation
} from './gateway-types';

//
// GATEWAY_EVENT_STARTED
//
export const GATEWAY_EVENT_STARTED = 'started';
export type StartedGatewayEvent = GatewayEvent<typeof GATEWAY_EVENT_STARTED>;
export const createStartedGatewayEvent = (): StartedGatewayEvent => ({
  type: GATEWAY_EVENT_STARTED
});

//
// GATEWAY_EVENT_STOPPED
//
export const GATEWAY_EVENT_STOPPED = 'stopped';
export type StoppedGatewayEvent = GatewayEvent<typeof GATEWAY_EVENT_STOPPED>;
export const createStoppedGatewayEvent = (): StoppedGatewayEvent => ({
  type: GATEWAY_EVENT_STOPPED
});

//
// GATEWAY_EVENT_URL_CHANGE_STARTED
//
export const GATEWAY_EVENT_URL_CHANGE_STARTED = 'urlChangeStarted';
export interface UrlChangeStartedGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_URL_CHANGE_STARTED> {
  readonly from: LocationSnapshot;
  readonly to: LocationSnapshot;
}
export const createUrlChangeStartedGatewayEvent = (
  from: LocationSnapshot,
  to: LocationSnapshot
): UrlChangeStartedGatewayEvent => ({
  type: GATEWAY_EVENT_URL_CHANGE_STARTED,
  from,
  to
});

//
// GATEWAY_EVENT_URL_CHANGE_REDIRECTED
//
export const GATEWAY_EVENT_URL_CHANGE_REDIRECTED = 'urlChangeRedirected';
export interface UrlChangeRedirectedGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_URL_CHANGE_REDIRECTED> {
  readonly location: LocationSnapshot;
}
export const createUrlChangeRedirectedGatewayEvent = (
  location: LocationSnapshot
): UrlChangeRedirectedGatewayEvent => ({
  type: GATEWAY_EVENT_URL_CHANGE_REDIRECTED,
  location
});

//
// GATEWAY_EVENT_URL_CHANGE_COMPLETED
//
export const GATEWAY_EVENT_URL_CHANGE_COMPLETED = 'urlChangeCompleted';
export interface UrlChangeCompletedGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_URL_CHANGE_COMPLETED> {
  readonly location: LocationSnapshot;
  readonly activeMfes: string[];
}
export const createUrlChangeCompletedGatewayEvent = (
  location: LocationSnapshot,
  activeMfes: string[]
): UrlChangeCompletedGatewayEvent => ({
  type: GATEWAY_EVENT_URL_CHANGE_COMPLETED,
  location,
  activeMfes
});

//
// GATEWAY_EVENT_URL_CHANGE_INTERRUPTED
//
export const GATEWAY_EVENT_URL_CHANGE_INTERRUPTED = 'urlChangeInterrupted';
export interface UrlChangeInterruptedGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_URL_CHANGE_INTERRUPTED> {
  readonly location: LocationSnapshot;
}
export const createUrlChangeInterruptedGatewayEvent = (
  location: LocationSnapshot
): UrlChangeInterruptedGatewayEvent => ({
  type: GATEWAY_EVENT_URL_CHANGE_INTERRUPTED,
  location
});

//
// GATEWAY_EVENT_URL_CHANGE_ERROR
//
export const GATEWAY_EVENT_URL_CHANGE_ERROR = 'urlChangeError';
export interface UrlChangeErrorGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_URL_CHANGE_ERROR> {
  readonly location: LocationSnapshot;
  readonly error: Error;
}
export const createUrlChangeErrorGatewayEvent = (
  location: LocationSnapshot,
  error: Error
): UrlChangeErrorGatewayEvent => ({
  type: GATEWAY_EVENT_URL_CHANGE_ERROR,
  location,
  error
});

//
// GATEWAY_EVENT_LINK_INTERCEPT
//
export const GATEWAY_EVENT_LINK_INTERCEPT = 'linkIntercept';
export interface LinkInterceptGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_LINK_INTERCEPT> {
  readonly location: LocationSnapshot;
  readonly params: RpcParams;
}
export const createLinkInterceptGatewayEvent = (
  location: LocationSnapshot,
  params: RpcParams
): LinkInterceptGatewayEvent => ({
  type: GATEWAY_EVENT_LINK_INTERCEPT,
  location,
  params
});

//
// GATEWAY_EVENT_LINK_INTERCEPT_ERROR
//
export const GATEWAY_EVENT_LINK_INTERCEPT_ERROR = 'linkInterceptError';
export const LINK_INTERCEPT_ERROR_HANDLER_MISSING = 'handlerMissing';
export const LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION = 'handlerException';
export interface LinkInterceptErrorGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_LINK_INTERCEPT_ERROR> {
  readonly location: LocationSnapshot;
  readonly params: RpcParams;
  readonly reason:
    | typeof LINK_INTERCEPT_ERROR_HANDLER_MISSING
    | typeof LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION;
  readonly error: Error;
}
export const createLinkInterceptErrorGatewayEvent = (
  location: LocationSnapshot,
  params: RpcParams,
  error: Error,
  reason:
    | typeof LINK_INTERCEPT_ERROR_HANDLER_MISSING
    | typeof LINK_INTERCEPT_ERROR_HANDLER_EXCEPTION
): LinkInterceptErrorGatewayEvent => ({
  type: GATEWAY_EVENT_LINK_INTERCEPT_ERROR,
  location,
  reason,
  error,
  params
});

//
// GATEWAY_EVENT_INJECTABLE_LOADED
//
export const GATEWAY_EVENT_INJECTABLE_LOADED = 'injectableLoaded';
export interface InjectableLoadedGatewayEvent<T>
  extends GatewayEvent<typeof GATEWAY_EVENT_INJECTABLE_LOADED> {
  readonly injectable: Injectable<T>;
  readonly instance: T;
  readonly dependencies: any;
  readonly stackTrace?: string;
}
export const createInjectableLoadedGatewayEvent = <T>(
  injectable: Injectable<T>,
  instance: T,
  dependencies: any,
  stackTrace?: string
): InjectableLoadedGatewayEvent<T> => ({
  type: GATEWAY_EVENT_INJECTABLE_LOADED,
  injectable,
  instance,
  dependencies,
  ...(stackTrace ? { stackTrace } : {})
});

//
// GATEWAY_EVENT_INJECTABLE_LOAD_ERROR
//
export const GATEWAY_EVENT_INJECTABLE_LOAD_ERROR = 'injectableLoadError';
export interface InjectableLoadErrorGatewayEvent<T>
  extends GatewayEvent<typeof GATEWAY_EVENT_INJECTABLE_LOAD_ERROR> {
  readonly injectable: Injectable<T>;
  readonly error: InjectableLoadError;
  readonly stackTrace?: string;
}
export const createInjectableLoadErrorGatewayEvent = <T>(
  injectable: Injectable<T>,
  error: InjectableLoadError,
  stackTrace?: string
): InjectableLoadErrorGatewayEvent<T> => ({
  type: GATEWAY_EVENT_INJECTABLE_LOAD_ERROR,
  injectable,
  error,
  ...(stackTrace ? { stackTrace } : {})
});

//
// GATEWAY_EVENT_MFE_MOUNTED
//
export const GATEWAY_EVENT_MFE_MOUNTED = 'mfeMounted';
export interface MfeMountedGatewayEvent<Links extends object>
  extends GatewayEvent<typeof GATEWAY_EVENT_MFE_MOUNTED> {
  readonly name: string;
  readonly mfe: MfeDeclaration<Links>;
  readonly impl: MfeImplementation<Links>;
}
export const createMfeMountedGatewayEvent = <Links extends object>(
  name: string,
  mfe: MfeDeclaration<Links>,
  impl: MfeImplementation<Links>
): MfeMountedGatewayEvent<any> => ({
  type: GATEWAY_EVENT_MFE_MOUNTED,
  name,
  mfe,
  impl
});

//
// GATEWAY_EVENT_MFE_MOUNT_ERROR
//
export const GATEWAY_EVENT_MFE_MOUNT_ERROR = 'mfeMountError';
export interface MfeMountErrorGatewayEvent<Links extends object>
  extends GatewayEvent<typeof GATEWAY_EVENT_MFE_MOUNT_ERROR> {
  readonly name: string;
  readonly mfe: MfeDeclaration<Links>;
  readonly impl: MfeImplementation<Links>;
  readonly error: Error;
}
export const createMfeMountErrorGatewayEvent = <Links extends object>(
  name: string,
  mfe: MfeDeclaration<Links>,
  impl: MfeImplementation<Links>,
  error: Error
): MfeMountErrorGatewayEvent<Links> => ({
  type: GATEWAY_EVENT_MFE_MOUNT_ERROR,
  name,
  mfe,
  impl,
  error
});

//
// GATEWAY_EVENT_MFE_MOUNTED
//
export const GATEWAY_EVENT_MFE_UNMOUNTED = 'mfeUnmounted';
export interface MfeUnmountedGatewayEvent<Links extends object>
  extends GatewayEvent<typeof GATEWAY_EVENT_MFE_UNMOUNTED> {
  readonly name: string;
  readonly mfe: MfeDeclaration<Links>;
  readonly impl: MfeImplementation<Links>;
}
export const createMfeUnmountGatewayEvent = <Links extends object>(
  name: string,
  mfe: MfeDeclaration<Links>,
  impl: MfeImplementation<Links>
): MfeUnmountedGatewayEvent<any> => ({
  type: GATEWAY_EVENT_MFE_UNMOUNTED,
  name,
  mfe,
  impl
});

//
// GATEWAY_EVENT_MFE_UNMOUNT_ERROR
//
export const GATEWAY_EVENT_MFE_UNMOUNT_ERROR = 'mfeUnmountError';
export interface MfeUnmountErrorGatewayEvent<Links extends object>
  extends GatewayEvent<typeof GATEWAY_EVENT_MFE_UNMOUNT_ERROR> {
  readonly name: string;
  readonly mfe: MfeDeclaration<Links>;
  readonly impl: MfeImplementation<Links>;
  readonly error: Error;
}
export const createMfeUnmountErrorGatewayEvent = <Links extends object>(
  name: string,
  mfe: MfeDeclaration<Links>,
  impl: MfeImplementation<Links>,
  error: Error
): MfeUnmountErrorGatewayEvent<Links> => ({
  type: GATEWAY_EVENT_MFE_UNMOUNT_ERROR,
  name,
  mfe,
  impl,
  error
});

//
// GATEWAY_EVENT_MFE_NO_IMPL
//
export const GATEWAY_EVENT_MFE_NO_IMPL = 'mfeNoImpl';
export interface MfeNoImplGatewayEvent<Links extends object>
  extends GatewayEvent<typeof GATEWAY_EVENT_MFE_NO_IMPL> {
  readonly name: string;
  readonly mfe: MfeDeclaration<Links>;
}
export const createMfeNoImplGatewayEvent = <Links extends object>(
  name: string,
  mfe: MfeDeclaration<Links>
): MfeNoImplGatewayEvent<Links> => ({
  type: GATEWAY_EVENT_MFE_NO_IMPL,
  name,
  mfe
});

//
// GATEWAY_EVENT_MFE_UNRESOLVED
//
export const GATEWAY_EVENT_MFE_UNRESOLVED = 'mfeUnresolved';
export interface MfeUnresolvedGatewayEvent
  extends GatewayEvent<typeof GATEWAY_EVENT_MFE_UNRESOLVED> {
  readonly injectable: InternalMfeImplementation<any>;
  readonly error: Error;
}
export const resolveChosenMfeErrorGatewayEvent = (
  injectable: InternalMfeImplementation<any>,
  error: Error
): MfeUnresolvedGatewayEvent => ({
  type: GATEWAY_EVENT_MFE_UNRESOLVED,
  injectable,
  error
});

//
// Bring all the events together
//
export interface GatewayEventMap {
  [GATEWAY_EVENT_STARTED]: StartedGatewayEvent;
  [GATEWAY_EVENT_STOPPED]: StoppedGatewayEvent;

  [GATEWAY_EVENT_URL_CHANGE_STARTED]: UrlChangeStartedGatewayEvent;
  [GATEWAY_EVENT_URL_CHANGE_REDIRECTED]: UrlChangeRedirectedGatewayEvent;
  [GATEWAY_EVENT_URL_CHANGE_COMPLETED]: UrlChangeCompletedGatewayEvent;
  [GATEWAY_EVENT_URL_CHANGE_INTERRUPTED]: UrlChangeInterruptedGatewayEvent;
  [GATEWAY_EVENT_URL_CHANGE_ERROR]: UrlChangeErrorGatewayEvent;

  [GATEWAY_EVENT_LINK_INTERCEPT]: LinkInterceptGatewayEvent;
  [GATEWAY_EVENT_LINK_INTERCEPT_ERROR]: LinkInterceptErrorGatewayEvent;

  [GATEWAY_EVENT_INJECTABLE_LOADED]: InjectableLoadedGatewayEvent<any>;
  [GATEWAY_EVENT_INJECTABLE_LOAD_ERROR]: InjectableLoadErrorGatewayEvent<any>;

  [GATEWAY_EVENT_MFE_MOUNTED]: MfeMountedGatewayEvent<any>;
  [GATEWAY_EVENT_MFE_MOUNT_ERROR]: MfeMountErrorGatewayEvent<any>;
  [GATEWAY_EVENT_MFE_UNMOUNTED]: MfeUnmountedGatewayEvent<any>;
  [GATEWAY_EVENT_MFE_UNMOUNT_ERROR]: MfeUnmountErrorGatewayEvent<any>;
  [GATEWAY_EVENT_MFE_NO_IMPL]: MfeNoImplGatewayEvent<any>;
  [GATEWAY_EVENT_MFE_UNRESOLVED]: MfeUnresolvedGatewayEvent;
}
export type GATEWAY_EVENT = keyof GatewayEventMap;
export interface GatewayEvent<T extends GATEWAY_EVENT> {
  readonly type: T;
}
